<script setup lang="ts">

</script>

<template>
    <div class="card border-0 shadow mb-lg-6 mb-5 hover-animate">
        <div class="card-img-top overflow-hidden gradient-overlay">
            <img
                title="Find all hostels in the World - Hostelz - Compare Prices"
                alt="Find all hostels in the World - Hostelz - Compare Prices"
                class="img-fluid lazyload"
                loading="lazy"
                src="/images/banner/about-prices.jpg"
            >
            <div class="card-img-overlay-bottom z-index-20">
                <div class="media text-white text-sm align-items-center">
                    <div class="media-body">
                        <p class="subtitle text-sm text-white mb-1">
                            Compare Hostel Prices Instantly!
                        </p>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="card-body">
            <h4 class="mb-2">
                You are Overpaying for Hostels - that Stops now!
            </h4>
            <p class="text-sm mb-3">
                Platforms like Hostelworld and Booking.com often have different prices for the SAME room. Even availability can vary depending on the site. 😱
            </p>
            <p class="text-sm mb-3">
                That’s where Hostelz steps in. We are the ultimate price comparison tool for hostels. Save up to 23% on your next booking.
            </p>
            <!--div class="text-center mb-3">
                <button
                    class="btn btn-lg btn-danger mt-2 mt-sm-0 js-open-search-location"
                    :title="`all hostels in ${city.name} - with prices`"
                    @click="showDatesAndScrollTop"
                >
                    <TheIcon icon-id="search-icon-3" width="35" height="35" /> Find All Hostels in {{ city.name }}
                </button>
            </div!-->
            <iframe
                width="100%"
                height="680"
                src="https://www.youtube.com/embed/yJ5WHN2N9kQ?feature=shared"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
            />
        </div>
    </div>
</template>

<style scoped lang="scss">

</style>
