<script setup lang="ts">
import { computed } from 'vue'
import TheIcon from '@/Components/TheIcon.vue'
import TheAuthorPageLink from '@/Components/the-author/TheAuthorPageLink.vue'
import { Author } from '@/Interfaces/Author'

const props = defineProps<{
    author: Author
}>()

const imgTitle = computed(() => 'Hostel Blog Writer ' + props.author.name)

</script>

<template>
    <div class="mt-5 mb-3">
        <p>Hostelz.com is the world’s most comprehensive hostel-focused travel platform. We bring together listings from all the major booking sites to help you easily compare prices, see real guest reviews, and find the best deals—no matter where you’re headed. Check out our <a href="/how-to-compare-hostel-prices-hostelz" title="How Hostelz Works">How It Works page</a>. </p>
        <p>Not sure which hostel to pick? Use our <a href="/compare" title="Comparing Hostels App">Hostel Comparizon Tool</a> to compare your favorite hostels side-by-side before you book.</p>
        <p>Let us help you travel smarter and sleep cheaper.</p>
    </div>
    <div class="article-author-bio bg-gray-800 p-4 p-md-5 mt-5 mb-3">
        <div class="media">
            <div class="mr-2 mr-md-4 p-1">
                <img
                    :title="imgTitle"
                    :alt="imgTitle"
                    :src="author.image"
                    class="avatar avatar-lg"
                >

                <span v-if="author.verified" style="margin-left: -20px; z-index: 1; position: relative;">
                    <TheIcon
                        icon-id="verified-user-hostelz-white"
                        width="24"
                        height="24"
                        class="align-top"
                    />
                </span>
            </div>

            <div class="media-body text-white">
                <h4 class="text-white">
                    About the author:
                    <TheAuthorPageLink :author="author" />
                </h4>

                <p class="text mb-2" v-html="author.bio" />
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">

</style>